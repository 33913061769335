import UserInfoManager from 'public/src/services/UserInfoManager'

function getKey() {
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'RECOMMENDPOP/GETKEY' })
  return memberId || gbCommonInfo?.SiteUID
}

function closePopupbyCloseIconLocalStorage() {
  const key = `click_feedback_rec_close_icon_${getKey()}`
  return {
    get() {
      return localStorage.getItem(key)
    },
    set() {
      localStorage.setItem(key, Date.now())
    },
    remove() {
      localStorage.removeItem(key)
    }
  }
}

function getTimeStampInfo() {
  const timeStamp = closePopupbyCloseIconLocalStorage().get()
  const timeDiff = Date.now() - timeStamp
  return {
    timeStamp, // 点击关闭icon的时间戳
    overOneDay: timeDiff > 1000 * 60 * 60 * 24 // 超过24小时
  }
}


function hidePopupLocalStorage() {
  const key = `hide_feedback_rec_${getKey()}`
  return {
    get() {
      return localStorage.getItem(key)
    },
    set() {
      localStorage.setItem(key, '1')
    },
    remove() {
      localStorage.removeItem(key)
    }
  }
}

// 
function showByLocalStorage() {
  if (getTimeStampInfo().overOneDay) {
    hidePopupLocalStorage().remove()
    closePopupbyCloseIconLocalStorage().remove()
    return true
  } else if (hidePopupLocalStorage().get() !== '1') {
    return true
  }
}

export {
  getKey,
  closePopupbyCloseIconLocalStorage,
  hidePopupLocalStorage,
  showByLocalStorage,
  getTimeStampInfo
}
