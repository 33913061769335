import schttp from 'public/src/services/schttp'
import { getQueryString } from '@shein/common-function'
import { getFeedbackRecInSession, feedbackAbtToRuleId } from 'public/src/pages/product_list_v2/js/utils'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'


function getParams() {
  const attr_ids = getQueryString({ key: 'attr_ids' })
  const { ymal, similar, goodsId } = getFeedbackRecInSession() || {}

  return { attr_ids, ymal, similar, goodsId }
}
// 实时反馈用的
export async function getFeedBackListGoods({ scene_id = 156, cate_ids, goodsId, productLimit = 0, listAbtResult, triggerEvent, discountRate, salePrice, isPaid, useBffApi, language }, requestInstance = null) {
  const { attr_ids, ymal, similar } = getParams()
  const ruleId = feedbackAbtToRuleId(listAbtResult, triggerEvent)

  let resultGoods = []
  const data = {
    scene_id: scene_id,
    location: 'RealtimeFeedbackJson',
    triggerEvent: triggerEvent,
    jsonRuleId: JSON.stringify(ruleId),
    limit: Number(productLimit ?? 0) + 10,
    newPreHandle: true,
    preGoodsHandlerConfig: {
      hideSuggestedSalePrice: true
    },
    contextParams: {
      goods_id: goodsId,
      cate_id: cate_ids,
      discount_rate: discountRate,
      goods_price: salePrice,
      filter_goods_yaml: ymal,
      filter_goods_similar: similar,
      click_nav_attr: attr_ids ? attr_ids : '',
      sort: 0
    },
    abtInfoV3: {
      newProductCard: true,
    },
    atomFields: {
      prices: true,
      sheinClubPromotionInfo: true,
      newFlashPromotion: true,
      stock: true,
      flashZonePromotion: true,
      vipDiscountPrices: true,
      estimatedPrice: 1,
    }
  }
  requestInstance?.abort()
  requestInstance = new SchttpAbortCon()
  if(useBffApi) {
    const result = await schttp({
      method: 'GET',
      url: '/product/recommend/feedback_products',
      signal: requestInstance.signal,
      params: {
        triggerEvent: triggerEvent,
        goods_id: goodsId,
        goods_cate_id: cate_ids,
        goods_discount: discountRate,
        goods_price: salePrice,
        filter_goods_yaml: ymal ?? '',
        filter_goods_similar: similar ?? '',
        click_nav_attr: attr_ids ?? '',
        filter: attr_ids ?? '',
        limit: 10, // 默认最多10个
        noNeedBuyBox: 1,
      },
      useBffApi: true,
      // headers: {
      //   'sheindebug': 676,
      //   'Content-Type': 'multipart/form-data',
      // },
    })
    // console.log(result, 'resultresultresultresultresult111')
    // 过滤售罄商品展示
    const isSoldOut = (item) => +item.stock === 0 || +item.is_on_sale === 0
    let goods = result?.info?.products.filter(item => !isSoldOut(item))?.slice(0, 30) || []
    let goodList = (goods || []).map(v => {
      return goodsTransformation(v, {
        language,
        lang: gbCommonInfo.lang ?? 'us',
        isPaid: !!isPaid
      })
    })
    return goodList
  }
  const result = await schttp({
    method: 'POST',
    url: '/api/recommend/facadeAtom/get',
    signal: requestInstance.signal,
    data,
  })
  const isSoldOut = (item) => +item.stock === 0 || +item.is_on_sale === 0
  resultGoods = result?.info?.products.filter(item => !isSoldOut(item))?.slice(0, 30) || []
  return resultGoods
}
