<template>
  <div class="wishlist-filter-empty">
    <i 
      v-if="gbContant.IS_RW" 
      class="suiiconfont sui_img_nodata_save_75px nodata_icon"
    ></i>
    <sui_icon_empty_save_75px_color 
      v-else
      class="wish-icon-empty"
      size="75px"
    />
    <p v-html="language.SHEIN_KEY_PWA_16098"></p>
    <s-button
      :type="['primary', 'H80PX']"
      class="reset-btn"
      @click="clickReset"
    >
      {{ language.SHEIN_KEY_PWA_15256 }}
    </s-button>
  </div>
</template>

<script>
import { Button } from '@shein/sui-mobile'
import { sui_icon_empty_save_75px_color } from '@shein-aidc/icon-vue2'
export default {
  components: {
    [Button.name]: Button,
    sui_icon_empty_save_75px_color
  },
  props: {
    constant: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    gbContant() {
      return typeof window === 'undefined' ? this.constant : gbCommonInfo // 兼容
    }
  },
  
  methods: {
    clickReset () {
      this.$emit('clickReset')
    },
  }
}
</script>

<style lang="less">
.wishlist-filter-empty {
  padding: 1.8133333rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
    color: #fc4070;
  }
  p {
    margin: 0.53333rem 0;
    text-align: center;
    font-size: 0.373333rem;
    font-family: "SF UI Display";
    color: #222;
    letter-spacing: 0.002px;
    line-height: 1.2;
  }
  .reset-btn {
    width: 4.26666666rem;
    height: 0.96rem;
    line-height: 0.96rem;
  }
}
</style>
